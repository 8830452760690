.width-side-right {
    width: 100%;
}

.width-side-left {
    width: 55%;
}

@media (min-width: 992px) {
    .width-side-right {
        width: 45%;
    }
}